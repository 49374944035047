
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';

import "./Calendar.css";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { API_URL } from '../../../redux/actions/api';
import { updateAppointment } from '../../../redux/actions/dataActions';
import { LoadingButton } from '@mui/lab';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';



function Calendar(props) {

    const dispatch = useDispatch();
    const [highlightedDays, setHighlightedDays] = useState([])
    const { loading } = useSelector((state) => state.async);
    const navigate = useNavigate()

    useEffect(() => {
        console.log(props)
        axios.get(API_URL +'doctors/' + props.visitor.schedule_id).then((res) =>{
            const slt = res.data.data.sort((a, b) => dayjs(a.wdate).isBefore(dayjs(b.wdate)));
            setSlots(slt)

            const firstSlot = slt[0]
            const initialValue = dayjs(firstSlot.wdate);
        
            setHighlightedDays(slt.filter(s => dayjs(s.wdate).isBefore(dayjs(initialValue).add(1, 'month').startOf('month')) &&  dayjs(s.wdate).isAfter(dayjs(initialValue).startOf('month')))
              .map(s => Number(dayjs(s.wdate).format('DD'))));

        })
      

    },[props.visitor.schedule_id])
  
    const [times, setTimes] = useState([]);
    const [slots, setSlots] = useState([]);
    const [currentSlot, setCCurrentSlot] = useState(null)
    
    const ServerDay = (props) => {
        const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;  
        const isSelected = !props.outsideCurrentMonth && highlightedDays.indexOf(props.day.date()) >= 0;
    
        return (
            <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} disabled={!isSelected} day={day} />  
        );
    }
  
    const Change = (value) => {
      //  setCurrentDate(value);
        let times = slots.filter(s => s.wdate == value.format('YYYY-MM-DD'));
        setTimes(times)  
    }
  
    const ChooseTime = (time) => {    
        setCCurrentSlot(time)
    }
  
    const handleMonthChange = (date) => {   
        setTimes([])
    
        setHighlightedDays(
        slots.filter(s => 
            dayjs(s.wdate).isBefore(date.add(1, 'month').startOf("month")) && dayjs(s.wdate).isAfter(dayjs(date).startOf('month')))
        .map(s => Number(dayjs(s.wdate).format('DD')))
        );  
    };

    
  const UpdateFunc = () => {
    if(!currentSlot) {
      return;
    }
    dispatch(updateAppointment(props.visitor.id, {
        slot_data_id : currentSlot.id,
        appointed_time: currentSlot.start_time,
        timeData : {
            wdate : currentSlot.wdate, 
        } 
    }))
    setTimeout(() => {
        props.close();
        navigate('/')
    }, 50);
}


    return (
        <>
        <div className='headDial'>
            <h3>עריכת תור</h3>
                <div className='drName'>{props?.visitor?.schedule.title}
            </div>
        </div>
        
         <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              //  defaultValue={initialValue}
                onChange={Change}
                onMonthChange={handleMonthChange}
                views={['day']}
                slots={{
                day: ServerDay,
                }}
                slotProps={{
                day: {
                    highlightedDays,
                },
                }}
            />
            </LocalizationProvider>

            <div className="time-warp edit_tor">  
            {times.map(time => (
                <span className={"time-slot " + (currentSlot?.id == time.id ? 'active' : '')} onClick={() => ChooseTime(time)}>{
                dayjs('2023-12-12 ' + time.start_time).format('HH:mm')
                }</span>
            ))}
            </div>
            <LoadingButton 
                disabled={!currentSlot} 
                loading={loading} 
                variant="contained" 
                className="close-btn edit_tor" 
                onClick={UpdateFunc} 
                sx={{ borderRadius: 0 }} 
                endIcon={<CheckIcon />}
            >
                 אישור 
            </LoadingButton>
        
        </>
    )
}

export default Calendar