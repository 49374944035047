// Async
export const ASYNC_ACTION_START = 'ASYNC_ACTION_START'
export const ASYNC_ACTION_FINISH = 'ASYNC_ACTION_FINISH'
export const ASYNC_ACTION_ERROR = 'ASYNC_ACTION_ERROR'
export const ASYNC_ACTION_CLEAN_ERROR = 'ASYNC_ACTION_CLEAN_ERROR'

// User
export const OTP='OTP'
export const RESTART_OTP='RESTART_OTP'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT = 'LOGOUT'
export const SET_AS_AUTHENTICATED = 'SET_AS_AUTHENTICATED'
export const USER_DATA = 'USER_DATA'

// base
export const HOME = 'HOME'
export const ALL_DEPARTMENTS = 'ALL_DEPARTMENTS'

export const ADD_VISITOR = 'ADD_VISITOR';
export const REMOVE_VISITOR = 'REMOVE_VISITOR';
export const UPDATE_VISITOR = 'UPDATE_VISITOR';
export const START_FORM = 'START_FORM';
// Modal
export const OPEN_MODAL = 'OPEN_LOGIN_MODAL'
export const CLOSE_MODAL = 'CLOSE_LOGIN_MODAL'