import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Appointments.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Tab, Card } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import axios from "axios";
import { API_URL } from "../../redux/actions/api";
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import moment from "moment";
import { ArrowBackIosOutlined, ArrowBackOutlined } from "@mui/icons-material";
import { startForm } from "../../redux/actions/dataActions";

function Appointments() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [currentTab, setCurrentTab] = useState('future')


  const { user } = useSelector((state) => state.auth);
  const visitors = user?.visitors || [];
  const fastVisitors = visitors.filter(v => moment(v.appointed_time).isBefore(moment()));
  const futureVisitors = visitors.filter(v => moment(v.appointed_time).isAfter(moment()));

  const Appointment = (app_data) => {
    const data = app_data.data;

    const department = user?.departments.find(d => d.id == data.department_id)

    return (
      <div>
        <Card className="appointment" onClick={() => navigate('/appointment/' + data.id)} >
          <div className="bold">
            {moment(data.appointed_time).format('DD/MM/YYYY')} בשעה {moment(data.appointed_time).format('HH:mm')}
            <br />
            {department.title}, {data.schedule?.title}
          </div>
          <br />
          <div>
            תור עבור {data.fname} {data.lname} <br />
            מרפאת {data.schedule?.address}
          </div>
          <ArrowBackIosNewIcon className="arrow_go" />
        </Card>
        <hr className="appointment_hr" />
      </div>

    )

  }

  return (
    <div className="homeWrap">
      <div className="title_head">
        <h1>התורים שלי</h1>
        <Button variant="contained" onClick={() => {
          dispatch(startForm());
          navigate('/form');}} 
          endIcon={<AddIcon />} sx={{ borderRadius: 20 }}  >לקביעת תור חדש</Button>
      </div>

      <TabContext value={currentTab}>
        <TabList className="tab-list" variant="fullWidth" onChange={(e, val) => setCurrentTab(val)}>
          <Tab label="תורים קרובים" value="future" className='selectedTabIndicator' />
          <Tab label="תורים קודמים" value="fast" />
        </TabList>

        <TabPanel value="future" >{futureVisitors.map(v => <Appointment data={v} key={v.id} />)}</TabPanel>
        <TabPanel value="fast">{fastVisitors.map(v => <Appointment data={v} key={v.id} />)}</TabPanel>
      </TabContext>

    </div>
  );
}

export default Appointments;