import React from "react";
import { useDispatch, useSelector, useEf } from "react-redux";
import "./Header.css";
import { logout } from "../../../redux/actions/authActions";
import logo from "../../../assets/images/ANS_TECH_LOGO_2.1.png";
import whatsApp from "../../../assets/images/whatsApp.svg";
import facebook from "../../../assets/images/facebook.svg";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Link, useNavigate } from 'react-router-dom'
import { getHomeData } from "../../../redux/actions/dataActions";
import { useEffect } from "react";

const maxMobileWidth = 767;

const Header = (props) => {
  const navigate = useNavigate()

  const dispatch = useDispatch();
  const { isAuthenticated, FirstName, LastName, user } = useSelector(
    (state) => state.auth
  );
  const { orders, data } = useSelector((state) => state.data);

  const logoutFunc = () => {
    dispatch(logout());
    window.location.replace("/");
  }

  const isMobile = window.innerWidth <= 760

  return (
    <section className="header is-sticky">
      <img src={logo} alt="logo" className="logo" onClick={() => navigate('/')} />
      {isAuthenticated ? (
        <></>
      ) : null}
      <div className="side-header">
        <div className="side-header-items">
          {isAuthenticated && <span className="logout" onClick={logoutFunc}>התנתקות</span>}
        </div>
      </div>

    </section>
  );
};

export default Header;
