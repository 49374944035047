import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Steps.css";
import { useLocation, useNavigate } from "react-router-dom";
import Card from '@mui/material/Card';


function Step2(props) {
  const location = useLocation();

  const navigate = useNavigate();
  
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth)
console.log(user)
  const Click = (id) => {
    props.setFormData({...props.formData, 
        department_id : id,      
    })
    props.next()
  }
  
  return (
    <div className="step step2">
        <h3>{props?.formData.fname}</h3>
        <h5>מהו התור המבוקש?</h5>
        {user?.departments?.map(d => (
            <Card onClick={() => Click(d.id)} key={d.id}>{d.title}</Card>
        ))}

        
    
        
    </div>
  );
}

export default Step2;
