import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from 'dayjs';
import "./Steps.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import CheckIcon from '@mui/icons-material/Check';

import axios from "axios";
import { sendAppointment } from "../../../redux/actions/dataActions";

function Step5(props) {
  const location = useLocation();
  const {user} = useSelector((state) => state.auth)
  const {loading} = useSelector((state) => state.async)
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const department = user?.departments.find(d => d.id == props.formData.department_id)

  const Next = () => {
    dispatch(sendAppointment(props.formData))
  }
 
  return (
    <div className="step step5">
        <h3>{props?.formData.fname}</h3>
        <h5>לאישור פרטי התור</h5>
        <Card>
        <div className="dep_title">{department.title}</div>
        <div>{props?.formData.doctor.title}</div>
        <br/>
        <div><b>{props?.formData.doctor.address}</b></div>

        <br/>
        <div className="flex_el">
          <div>
            תאריך <br/>
            <b>{dayjs(props.formData.timeData.wdate).format('DD-MM-YYYY')}</b>
          </div>
          <div>
            שעה <br/>
            <b>{props.formData.timeData.start_time.slice(0,-3)}</b>
          </div>
        </div>

        </Card>

        <LoadingButton 
        variant="contained" 
        loading={loading}
        className="next-btn send_b" 
        onClick={Next}
        endIcon={<CheckIcon />}
        >אישור</LoadingButton>
        
    </div>
  );
}

export default Step5;
