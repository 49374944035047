import { HOME,  ALL_DEPARTMENTS, ADD_VISITOR, START_FORM } from "../actions/types";

const initialState = {
 success_visitor: false
};

const dataReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case HOME:
      return {
        ...state,
        data: payload,
      }
    case ADD_VISITOR:
      return {
        ...state,
        success_visitor: true
      }
      case START_FORM:
        return {
          ...state,
          success_visitor: false
        }
   
    default:
      return state;
  }
};

export default dataReducer;
