import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_AS_AUTHENTICATED,
  USER_DATA,
  OTP,
  RESTART_OTP,
  ADD_VISITOR,
  REMOVE_VISITOR,
  UPDATE_VISITOR
} from "../actions/types";

const initialState = {
  user: undefined,
  AuthToken: undefined,
  refreshToken: undefined,
  isAuthenticated: false,
  registerSuccess: false,
  Name: undefined,
  otp: false,
  errorMsg : '',
};

const authReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_AS_AUTHENTICATED:
      localStorage.setItem("token", payload);
      //localStorage.setItem("AuthToken", payload);
      return {
        ...state,
        AuthToken: payload, // puts token in state
        isAuthenticated: true,
      };
    case OTP:
      return {
        ...state,
        otp: true,
        errorMsg: '',
      };
    case RESTART_OTP:
      return {
        ...state,
        otp: false,
        errorMsg: payload.errorMsg
      };
    case LOGIN_SUCCESS:
      localStorage.setItem("token", payload.token);
     
      localStorage.setItem("FirstName", payload.FirstName);
      localStorage.setItem("LastName", payload.LastName);
      return {
        ...state,
        AuthToken: payload.token, // puts token in state
        isAuthenticated: true,
        FirstName: payload.FirstName,
        LastName: payload.LastName,
        user: payload,
        errorMsg: '',
      };
    case LOGIN_FAIL:
      return {
        ...state, 
        errorMsg : payload.errorMsg
      }
    case LOGOUT:
      localStorage.removeItem("token");
     
      localStorage.removeItem("Name");
      return {
        ...state,
        AuthToken: null,
        isAuthenticated: false,
        user: null,
        errorMsg: '',
      };
    case USER_DATA:
      return {
        ...state,
        user: payload,
      };
    case ADD_VISITOR:
      return {
        ...state,
        user: {...state.user, visitors: [...state.user.visitors, payload]},
      }
      case UPDATE_VISITOR:
        console.log(payload)
      return {
        ...state,
        user: {...state.user, visitors: state.user.visitors.map(v => v.id == payload.id ? payload : v)},
      }
      case REMOVE_VISITOR:
        return {
          ...state,
          user : {...state.user, visitors: state.user.visitors?.filter(v => v.id !=  payload)},
        }
    default:
      return {
        ...state,      
        FirstName: localStorage.getItem("FirstName"),
        LastName: localStorage.getItem("LastName"),
      };
  }
};

export default authReducer;
