import { useDispatch, useSelector } from "react-redux";
import "./Appointments.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from "@mui/material/Typography";


import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useEffect, useState } from "react";
import { CloseOutlined } from "@mui/icons-material";
import { deleteAppointment, updateAppointment } from "../../redux/actions/dataActions";
import Calendar from "./calendar/Calendar";

function Appointment() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const note = "לקראת הגעתך לטיפול ישנם מספר דגשים והנחיות מיוחדות שיש להקפיד עליהם <br/><br/> " +
    '<b>מידע רפואי קודם</b> במידה ויש ברשותך צילומים או מידע רפואי אחר, יש להביא אותם עימך'

  const [delOpen, setDelOpen] = useState(false)
  const [editOpen, setEditOpen] = useState(false)
  const [slot_data_id, setSlotDataId] = useState(null);
  const [date, setDate] = useState(null)
  const { user } = useSelector((state) => state.auth);
  const visitor = user?.visitors?.find(v => v.id == location.pathname.replace('/appointment/', ''));

  const DeleteFunc = () => {
    dispatch(deleteAppointment(visitor.id))
  }

  useEffect(() => {
    if (!visitor) {
      navigate('/')
    }
  }, [visitor])

  return (
    <div className="">
      <IconButton aria-label="back" onClick={() => navigate('/')} className="back_btn" size="small" >
        <ArrowForwardIosIcon />
      </IconButton>
      <div className="note_warp" >
        <h3>הנחיות לפני טיפול</h3>
        <div className='notes' dangerouslySetInnerHTML={{ __html: /*visitor?.note*/ note }} ></div>
      </div>

      <div className="control-inputs">
        <a className="del" onClick={() => setDelOpen(true)}>לביטול התור</a>
        <a className="edit" onClick={() => setEditOpen(true)}>לעריכת תור</a>
      </div>

      <Dialog
        open={delOpen}
        onClose={() => setDelOpen(false)}
        className="del_dialog"
      >
        <IconButton
          aria-label="close"
          onClick={() => setDelOpen(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseOutlined />
        </IconButton>
        <DialogContent id="alert-del-dialog">
          <DialogContentText>
            <br /><br></br>
            <b className="delTor">? האם לבטל את התור שלך</b>
            <br /><br></br>

          </DialogContentText>

          <Typography color="primary">
            <a className="del-btn" onClick={DeleteFunc} color="primary.main" >אישור</a>
          </Typography>

          <Button variant="contained" className="close-btn" onClick={() => setDelOpen(false)} sx={{ borderRadius: 0 }} >
            לא לבטל
          </Button>
        </DialogContent>

      </Dialog>


      <Dialog
        open={editOpen}
        onClose={() => setEditOpen(false)}
        className="del_dialog"
      >
        <IconButton
          className="closeIcon" 
          aria-label="close"
          onClick={() => setEditOpen(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseOutlined />
        </IconButton>
        <DialogContent className="alert_edit_dial" id="alert-del-dialog">
          <Calendar visitor={visitor} close={() => setEditOpen(false)} />   
        </DialogContent>
      </Dialog>

      

      <Button variant="contained"
        className="next-btn" onClick={() => navigate('/')}>הבנתי תודה</Button>

    </div>
  );
}

export default Appointment;
