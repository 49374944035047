import {Provider} from 'react-redux' // redux
import store from './redux/store/store' // Redux
import { BrowserRouter as Router } from 'react-router-dom'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {heIL} from '@mui/material/locale';
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import Content from './components/content/Content';
import { grey } from '@mui/material/colors';
import './App.css'
function App() {
  
const theme = createTheme({
  direction: 'rtl', // Both here and <body dir="rtl">
  palette: {
    primary: {
      main:'#15497c', // '#890f13', // 
    },
    secondary: {
      main: grey[500],
    },
  },
  typography: {
    fontSize: 16,
    button: {
      fontWeight: 700,
    }, 
    
  },
  shape: {
    borderRadius: 50,
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 0,
      }, 
    }, 
  }, 

 
},
heIL,
);
  
  
// Create rtl cache
const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
},);
  return (
    <Router>
      <Provider store={store}>
         <CacheProvider value={cacheRtl}>
          <ThemeProvider theme={theme}>
              <div dir="rtl" className='phone_container'>
                <Content />
              </div>
            </ThemeProvider>
          </CacheProvider>
      </Provider>
    </Router>
  )
}

export default App
